import {
    fetchMainPopular,
    fetchMainProductsReviews,
    fetchMainPortfolio,
    fetchMainReviews,
    fetchVideos, fetchQuestions, fetchMainNumbersBaltex
} from "@/redux/action-creaters/content";
import {wrapper} from "@/redux";
import {fetchMarks, fetchMarksBaltex} from "@/redux/action-creaters/models";
import {fetchMainNumbers} from "@/redux/action-creaters/content";
import {fetchShops} from "@/redux/action-creaters/location";
import {fetchMainAdv} from "@/redux/action-creaters/content";
import {fetchMainSlider} from "@/redux/action-creaters/content";
import {fetchMainNews} from "@/redux/action-creaters/content";
import {setDescription, setTitle} from "@/redux/action-creaters/meta"
import Redis from '@/lib/redis'
import {Api} from "@/api"
// @ts-ignore
import Cookies from 'cookies';
import pagesWrapper from "~/utils/pagesWrapper";
import LayoutWrapper from "~/components/layouts/layoutWrapper";
import BaltexHome from "~/components/baltex/pages";
import FarkopHome from "~/components/pages";
import {getBaltexSections} from "~/redux/action-creaters/baltex";
import {checkIsBaltex, checkIsLanding} from "~/utils";
import LandingsHome from "~/components/landings/pages";

export default function Home({scripts, host, isBaltex, isLanding, slider, models, adv, productions, news}: any) {
      return (
        <LayoutWrapper scripts={scripts} host={host} isMain={true}>
            {isBaltex && (
                <BaltexHome slider={slider} adv={adv} productions={productions} news={news}/>
            )}
            {isLanding && (
                <LandingsHome slider={slider} models={models} adv={adv}/>
            )}
            {!isBaltex && !isLanding && (
                <FarkopHome host={host}/>
            )}
        </LayoutWrapper>
      )
}

export const getServerSideProps = wrapper.getServerSideProps(
    function (store): any {
        return async ({req, query, params, res, resolvedUrl}: any) => {
            const data: any = {};
            const reset = Boolean(query.reset);
            //req.headers.host = 'baltex.ru';
            const host = req.headers.host;
            if (checkIsBaltex(host)) {
                data.slider = await Api.baltex.getMainSlider(Redis, reset);

                await store.dispatch(getBaltexSections(reset))
                await store.dispatch(fetchMarksBaltex(Redis, reset))
                data.adv = await Api.baltex.getMainAdv(Redis, reset);
                data.productions = await Api.baltex.getMainProduction(Redis, reset);
                data.news = await Api.baltex.getMainNews(Redis, reset);

                await store.dispatch(fetchQuestions(Redis, reset))

                await store.dispatch(fetchMainNumbersBaltex(Redis, reset))
            } else if (checkIsLanding(host)) {
                data.slider = await Api.lendings.getMainSlider(Redis, host, reset);
                data.models = await Api.lendings.getModels(Redis, host, reset);
                data.adv = await Api.lendings.getMainAdv(Redis, reset);

                await store.dispatch(fetchQuestions(Redis, reset))
            } else {
                store.dispatch(setTitle('Farkop.ru - фаркопы и дополнительное оборудование для автомобилей с установкой'))
                store.dispatch(setDescription('В компании «Фаркоп.ру» вы можете купить и установить фаркоп в Москве, Санкт-Петербурге и Нижнем Новгороде, дополнительное оборудование и аксессуары для автомобилей. Производим отправку в регионы России.'))


                await store.dispatch(fetchMainSlider(Redis, reset))
                await store.dispatch(fetchMarks(Redis, reset))
                await store.dispatch(fetchMainPopular(Redis, reset))
                await store.dispatch(fetchMainAdv(Redis, reset))
                await store.dispatch(fetchMainNews(Redis, reset, req.headers.host))
                await store.dispatch(fetchMainProductsReviews(Redis, reset, req.headers.host))
                await store.dispatch(fetchMainPortfolio(Redis, reset, req.headers.host))

                await store.dispatch(fetchMainNumbers(Redis, reset))
            }
            await store.dispatch(fetchMainReviews(Redis, reset))
            await store.dispatch(fetchVideos(Redis, reset))
            await store.dispatch(fetchShops(Redis, reset))


            if (query.PHPSESSID) {
                const cookies = new Cookies(req, res)
                cookies.set('PHPSESSID', query.PHPSESSID, {
                    httpOnly: true,
                    sameSite: 'lax',
                })
                return {
                    redirect: {
                        destination: '/',
                        permanent: true,
                    },
                }
            }

            return await pagesWrapper(data, store, Redis, {
                req,
                query
            }, '/');
        }
    }
)